
import { setSelected } from '@/SharedChart/Chooser/module';
import { setToast } from '@/store';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
    data: () => ({ name: '', description: '', isPublic: true }),
    computed: {
        collections(){
            return this.$store.state.chooser!.collections;
        }
    },
    methods: {
        async submit(){
            const { collectionId } = this.$route.params;
            const { name, description, isPublic } = this;
            const { selected, legends } = this.$store.state.chooser!;
            
            const errors = [];
            if(!selected.length)
                errors.push('לא נבחרו שאלות');
            if(!name.length)
                errors.push('לא ניתן לשאלון שם');
            if(errors.length)
                return setToast({ text: errors.join(', ') });

            const target = '/questions/collection' + (collectionId ? `/${collectionId}` : '');
            try{
                await this.$request(target, 'put', { name, description, public: isPublic, questions: selected, legends });
                this.$router.push('/questions');
                setToast({ text: 'השאלון נשמר בהצלחה!' });
            }catch(error){
                console.error(error)
            }
        },
        setQuestionnaire(){
             const collectionId = parseInt(this.$route.params.collectionId as string);
            if(isNaN(collectionId))
                return

            const collection = this.collections.find(c => c.id === collectionId)!;
            const { name, questions, legends, description, public: isPublic } = collection;
            Object.assign(this, { name, description, isPublic: isPublic ? true : false });

            setSelected({ selected: questions, legends });
        }

    }
});
