<template>
    <chooser
        title='בחירת שאלות'
        tooltipIntent='להוסיף למקבץ שלך'
        tooltipAction='{fill} את הפרטים שלו'
        @submit='submit'
        submitText='שמור'
        noCollections
        allowLegends
        :onLoad='setQuestionnaire'
    >
        <div id='collectionDetails'>
            <h1>יצירת מקבץ שאלות</h1>
            <div>
                <label>
                    שם המקבץ (שדה חובה)
                    <input v-model='name' />
                </label>
                <label>
                    תיאור המקבץ
                    <input v-model='description' id='collectionDescription' />
                </label>
                <label>
                    זמין באופן פומבי?
                    <switch-component v-model='isPublic' size='large' />
                </label>
            </div>
        </div>
    </chooser>
</template>

<script lang='ts'>
import { setSelected } from '@/SharedChart/Chooser/module';
import { setToast } from '@/store';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
    data: () => ({ name: '', description: '', isPublic: true }),
    computed: {
        collections(){
            return this.$store.state.chooser!.collections;
        }
    },
    methods: {
        async submit(){
            const { collectionId } = this.$route.params;
            const { name, description, isPublic } = this;
            const { selected, legends } = this.$store.state.chooser!;
            
            const errors = [];
            if(!selected.length)
                errors.push('לא נבחרו שאלות');
            if(!name.length)
                errors.push('לא ניתן לשאלון שם');
            if(errors.length)
                return setToast({ text: errors.join(', ') });

            const target = '/questions/collection' + (collectionId ? `/${collectionId}` : '');
            try{
                await this.$request(target, 'put', { name, description, public: isPublic, questions: selected, legends });
                this.$router.push('/questions');
                setToast({ text: 'השאלון נשמר בהצלחה!' });
            }catch(error){
                console.error(error)
            }
        },
        setQuestionnaire(){
             const collectionId = parseInt(this.$route.params.collectionId as string);
            if(isNaN(collectionId))
                return

            const collection = this.collections.find(c => c.id === collectionId)!;
            const { name, questions, legends, description, public: isPublic } = collection;
            Object.assign(this, { name, description, isPublic: isPublic ? true : false });

            setSelected({ selected: questions, legends });
        }

    }
});
</script>

<style lang="scss">
#collectionDetails {
    width: 60vw;
    margin: 0 auto;

    & h1 {
        margin-top: 2rem;
        width: unset;
    }

    & > div {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 0 0 $corner $corner;
        box-shadow: -4px 4px 12px -8px #aaa;
        margin-bottom: 2rem;
        padding: 1rem 2rem 1.5rem;
    }

    & label {
        display: flex;
        flex-direction: column;
        margin: 0;

        & > * {
            margin-top: 0.5rem;
        }

        & .switchButton {
            margin: 1rem auto 0 !important;
        }

        & #collectionDescription {
            width: 20rem;
        }
    }

    // & textarea {
    //     border: 1px solid #eee;
    //     border-radius: $corner;
    //     box-shadow: 0 0.125rem 0.25rem #ddd;
    //     padding: 0.5rem;
    //     width: 15rem;

    //     &:focus {
    //         border-color: #96b7d6;
    //         outline: none;
    //     }
    // }
}
</style>